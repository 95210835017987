[data-aos="picAnimation"] {
  opacity: 1;
  transition-property: transform, opacity;
}

[data-aos="picAnimation"].aos-animate {
  opacity: 0.3;
}

[data-aos="landingAnimation"] {
  opacity: 1;
  transition-property: transform, opacity;
}

[data-aos="landingAnimation"].aos-animate {
  opacity: 0.3;
}
